<template>
  <div class="app">
    <header>
      <div class="logo">
        <img src="./assets/logo.png" alt="txt2Video" />
        <span class="en">txt2Video v1.6</span>
        <span class="cn">纯文字动感视频</span>
      </div>
    </header>
    <h3>文字转语音+字幕</h3>
    <main>
      <div class="input-section">
        <div class="input_note_feedback">
          最多支持300字，生成的每一行会以标点符号断句，<br />所以符号之间的文字不要超过12个字，以免单行文字太长
        </div>
        <el-input
          type="textarea"
          :rows="10"
          :maxlength="300"
          show-word-limit
          v-model="inputText"
          placeholder="请输入您的文本内容"
        >
        </el-input>
      </div>
      <div class="voice-section">
        <div class="tab-buttons">
          <button
            v-for="(category, index) in filteredCategories"
            :key="category.CategoryName"
            :class="{ active: currentTab === index }"
            @click="currentTab = index"
          >
            {{ category.CategoryName }}
          </button>
        </div>
        <div class="voice-list">
          <div
            v-for="voice in currentVoices"
            :key="voice.VoiceName"
            class="voice-item"
            :class="{
              active: selectedVoice === voice,
              'is-playing': isPlaying && playingVoice === voice.VoiceAudio,
            }"
            @click="selectVoice(voice)"
          >
            <div class="voice-avatar" :class="voice.VoiceGender === 'male' ? 'male' : 'female'"></div>
            <div class="voice-info">
              <h3>{{ voice.VoiceName }}</h3>
              <p>{{ voice.VoiceDesc }}</p>
              <div class="sample-text">
                <span
                  class="play-icon"
                  :class="{ playing: isPlaying && playingVoice === voice.VoiceAudio }"
                  @click.stop="playAudio(voice.VoiceAudio)"
                >
                  <i class="fas fa-play"></i>
                </span>
                <span class="text">{{ voice.VoiceText }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="speed-settings">
          <h3>语速设置</h3>
          <el-slider
            v-model="speedValue"
            :step="0.5"
            :marks="speedMarks"
            :min="-1"
            :max="2"
            tooltip-class="custom-tooltip"
          ></el-slider>
        </div>
        <div class="convert-button-wrapper">
          <!-- <button @click="startStreamSynthesis">实时语音合成</button> -->
          <button class="convert-button" @click="synthesizeSpeech" v-if="!isLoading">开始合成</button>
          <div class="loading-spinner" v-else>
            <div class="spinner"></div>
            <span>正在合成...</span>
          </div>
        </div>
      </div>
    </main>

    <div class="error">{{ error }}</div>

    <!-- <div class="error">{{ isStreamSynthesisPlaying }}</div> -->

    <RealtimeSubtitles :subtitles="subtitles" :audioStartTime="audioStartTime" />

    <div v-if="synthesizedAudioUrl" class="subtitles-timeline">
      <h3>语音+字幕结果</h3>
      <audio ref="synthesizedAudio" controls :src="synthesizedAudioUrl" />
      <SubtitlesTimeline :subtitles="subtitles" />
    </div>

    <div v-if="synthesizedAudioUrl" class="mp3_to_video">
      <h3>视频配置预览</h3>
      <div class="video-conversion-container">
        <div class="video_preview">
          <VideoPreview :audioUrl="synthesizedAudioUrl" :subtitles="subtitles" :config="videoConfig" />
        </div>
        <VideoConfig v-model="videoConfig" />
      </div>
    </div>

    <extraInfo />

    <footer>
      <p>Powered by AI & Mask in 2024</p>
    </footer>
  </div>
</template>

<script>
import Fingerprint2 from "fingerprintjs2";
import axios from "axios";
import extraInfo from "./extraInfo.vue";
import SubtitlesTimeline from "./SubtitlesTimeline.vue";
import VideoPreview from "./VideoPreview.vue";
import VideoConfig from "./VideoConfig.vue";
import RealtimeSubtitles from "./RealtimeSubtitles.vue";

export default {
  data() {
    return {
      uid: null,
      apiUrl: process.env.VUE_APP_API_URL,
      categories: [],
      currentTab: 0,
      voices: [],
      selectedVoice: null,
      inputText: "我们常吃的大豆油，玉米油，花生油等\n基本都是富含欧米伽6脂肪酸的油。",
      isPlaying: false,
      playingVoice: null,
      audio: null,
      error: "",

      ws: null,
      mediaSource: null,
      sourceBuffer: null,
      audioElement: null,
      chunkQueue: [],
      isStreamSynthesisPlaying: false,
      audioStartTime: 0,

      synthesizedAudioUrl: "",
      subtitles: [],
      isLoading: false,
      speedValue: 0,
      speedMarks: {
        "-1": "0.8倍速",
        "-0.5": "0.9倍速",
        0: "1倍速",
        0.5: "1.1倍速",
        1: "1.2倍速",
        1.5: "1.35倍速",
        2: "1.5倍速",
      },

      videoConfig: {
        font: "DeYiHei",
        animation: "oneLine",
        alignment: "center",
        maxLines: 3,
        background: "color",
        backgroundColor: "#000000",
        backgroundImage: 1,
      },
    };
  },
  components: {
    extraInfo,
    SubtitlesTimeline,
    VideoPreview,
    VideoConfig,
    RealtimeSubtitles,
  },
  computed: {
    filteredCategories() {
      return this.categories.filter((category) => ["外语", "方言"].indexOf(category.CategoryName) == -1);
    },
    currentVoices() {
      return this.filteredCategories[this.currentTab]?.VoiceList;
    },
  },
  mounted() {
    this.fetchVoices();
    this.generateUID();
  },
  beforeUnmount() {
    this.stopAudio();
    if (this.audioElement) {
      this.audioElement.pause();
      this.audioElement.src = "";
    }
    if (this.mediaSource) {
      if (this.mediaSource.readyState === "open") {
        this.mediaSource.endOfStream();
      }
    }
    if (this.ws) {
      this.ws.close();
    }
  },
  methods: {
    generateUID() {
      Fingerprint2.get((components) => {
        const values = components.map((component) => component.value);
        this.uid = Fingerprint2.x64hash128(values.join(""), 31);
      });
    },
    async fetchVoices() {
      console.log('`${this.apiUrl}/voices`',`${this.apiUrl}/voices`);
      try {
        const response = await axios.get(`${this.apiUrl}/voices`);
        if (response.data && Array.isArray(response.data)) {
          this.categories = response.data;
        } else {
          throw new Error("Unexpected response format");
        }
      } catch (error) {
        console.error("Error fetching voices:", error);
        this.error = error.message;
      }
    },
    selectVoice(voice) {
      this.selectedVoice = voice;
      this.stopAudio();
    },

    initializeMediaSource() {
      this.mediaSource = new MediaSource();
      this.audioElement = new Audio();
      this.audioElement.src = URL.createObjectURL(this.mediaSource);

      this.mediaSource.addEventListener("sourceopen", () => {
        try {
          this.sourceBuffer = this.mediaSource.addSourceBuffer("audio/mpeg");
          // 移除设置 mode 的行
          this.sourceBuffer.addEventListener("updateend", this.onSourceBufferUpdateEnd);
        } catch (error) {
          console.error("Error initializing SourceBuffer:", error);
          this.$message.error("初始化音频播放器失败");
        }
      });
    },

    async startStreamSynthesis() {
      if (!this.selectedVoice) {
        this.$message.error("请先选择一个声音");
        return;
      }

      this.chunkQueue = [];
      this.initializeMediaSource();

      const response = await fetch(`${this.apiUrl}/synthesis-stream`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          text: this.inputText,
          voiceId: this.selectedVoice.VoiceType,
          speed: this.speedValue,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { wsUrl } = await response.json();
      console.log("Received WebSocket URL:", wsUrl);

      this.ws = new WebSocket(wsUrl);
      this.ws.binaryType = "arraybuffer";

      this.ws.onopen = this.handleWebSocketOpen;
      this.ws.onmessage = this.handleWebSocketMessage;
      this.ws.onerror = this.handleWebSocketError;
      this.ws.onclose = this.handleWebSocketClose;

      this.initializeMediaSource();
      this.isStreamSynthesisPlaying = true;
    },

    handleWebSocketOpen() {
      console.log("WebSocket connection opened");
    },

    handleWebSocketMessage(event) {
      if (typeof event.data === "string") {
        const data = JSON.parse(event.data);

        if (data.ready === 1) {
          this.sendSynthesisText();
        } else if (data.final === 1) {
          this.ws.close();
        } else if (data.result && data.result.subtitles) {
          this.handleSubtitles(data.result.subtitles);
        }
      } else if (event.data instanceof ArrayBuffer) {
        this.appendChunkToSourceBuffer(event.data);
        if (this.audioStartTime === 0) {
          this.audioStartTime = Date.now();
        }
      }
    },

    handleWebSocketError(error) {
      console.error("WebSocket error:", error);
      this.isStreamSynthesisPlaying = false;
    },

    handleWebSocketClose(event) {
      console.log("WebSocket connection closed:", event.code, event.reason);
      this.isStreamSynthesisPlaying = false;
    },

    sendSynthesisText() {
      this.sessionId = "session-" + Date.now() + "-" + Math.random().toString(36).substr(2, 9);
      this.messageId = this.generateMessageId();
      const message = {
        session_id: this.sessionId,
        message_id: this.messageId,
        action: "ACTION_SYNTHESIS",
        data: this.inputText,
      };
      this.ws.send(JSON.stringify(message));
      this.sendSynthesisTextEnd();
    },
    sendSynthesisTextEnd() {
      this.sessionId = "session-" + Date.now() + "-" + Math.random().toString(36).substr(2, 9);
      this.messageId = this.generateMessageId();
      const message = {
        session_id: this.sessionId,
        message_id: this.messageId,
        action: "ACTION_COMPLETE",
        data: "",
      };
      this.ws.send(JSON.stringify(message));
    },

    appendChunkToSourceBuffer(chunk) {
      if (this.sourceBuffer && !this.sourceBuffer.updating) {
        try {
          this.sourceBuffer.appendBuffer(chunk);
        } catch (error) {
          console.error("Error appending buffer:", error);
          // 如果发生错误，可能需要重置 MediaSource
          this.resetMediaSource();
        }
      } else {
        // 如果 sourceBuffer 正在更新，将 chunk 加入队列
        this.chunkQueue.push(chunk);
      }
    },
    resetMediaSource() {
      if (this.mediaSource.readyState === "open") {
        this.mediaSource.endOfStream();
      }
      this.initializeMediaSource();
    },

    onSourceBufferUpdateEnd() {
      // 处理队列中的下一个 chunk
      if (this.chunkQueue.length > 0 && !this.sourceBuffer.updating) {
        const nextChunk = this.chunkQueue.shift();
        this.appendChunkToSourceBuffer(nextChunk);
      }

      if (this.audioElement.paused && this.sourceBuffer.buffered.length > 0) {
        const bufferedEnd = this.sourceBuffer.buffered.end(0);
        if (this.audioElement.currentTime < bufferedEnd) {
          this.audioElement.play().catch((error) => {
            console.error("Error playing audio:", error);
          });
        }
      }
    },

    handleSubtitles(subtitles) {
      this.subtitles = this.subtitles.concat(subtitles);
      // 实现字幕显示逻辑
    },

    generateMessageId() {
      return "msg-" + Date.now() + "-" + Math.random().toString(36).substr(2, 9);
    },
    isValidText(text) {
      const tempArr = text.split("\n");
      let isValid = true;
      for (let i = 0; i < tempArr.length; i++) {
        const line = tempArr[i].replace(/([,.:?!，。：？！])/g, "").trim();
        console.log("line", line);
        if (line.length > 18) {
          this.$message.error(`此行文本超过18个字：${line}`);
          isValid = false;
          break; // 找到第一个超长的行就停止循环
        }
      }
      return isValid;
    },

    async synthesizeSpeech() {
      if (!this.selectedVoice) {
        this.$message.error("请先选择一个声音");
        return;
      }

      this.isLoading = true;
      this.synthesizedAudioUrl = "";
      this.error = "";

      try {
        const formattedText = this.formatText(this.inputText);
        if (!this.isValidText(formattedText)) {
          throw new Error("Unexpected response format");
        }
        const response = await axios.post(`${this.apiUrl}/synthesize`, {
          text: formattedText,
          voiceId: this.selectedVoice.VoiceType,
          speed: this.speedValue,
        });

        if (response.data && response.data.audio && response.data.subtitles) {
          this.synthesizedAudioUrl = `data:audio/wav;base64,${response.data.audio}`;
          this.subtitles = response.data.subtitles;
          this.$nextTick(() => {
            this.$message.success("语音合成成功");
          });
        } else {
          throw new Error("Unexpected response format");
        }
      } catch (error) {
        this.$message.error(error.response.data.error);
        this.error = error.response.data.error;
      } finally {
        this.isLoading = false;
      }
    },

    formatText(text) {
      return text.replace(/([,.:?!，。：？！])/g, "$1\n").trim();
    },

    playAudio(audioUrl) {
      console.log("audioUrl", audioUrl);
      if (this.isPlaying && this.playingVoice === audioUrl) {
        this.stopAudio();
      } else {
        this.stopAudio();
        this.audio = new Audio(audioUrl);
        this.audio.play();
        this.isPlaying = true;
        this.playingVoice = audioUrl;
        this.audio.onended = () => {
          this.isPlaying = false;
          this.playingVoice = null;
        };
      }
    },

    stopAudio() {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
        this.isPlaying = false;
        this.playingVoice = null;
      }
    },
  },
};
</script>

<style lang="less">
@import url("index.less");
</style>
